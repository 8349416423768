.menu {
	margin-bottom: 0;
}

.stickyNav{
	
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 10;
	background-color: white;
	width: 100%;
	
	&#_desktop_top_menu{
		top: 136px;
	}
	
	#adtm_menu{
		margin: auto;
		max-width: $width-compact;
	}
	
}

.top-menu {
	&[data-depth="1"] {
		margin: 0.625rem;
	}

	&[data-depth="0"] {
		margin-bottom: 0;
	}

	a:not([data-depth="0"]) {
		display: block;
		padding: 0.625rem;
		font-weight: 400;
		color: $gray;
	}

	a.dropdown-submenu {
		font-weight: 600;
		color: $gray-darker;
		text-transform: uppercase;
	}

	a[data-depth="0"] {
		padding: $small-space;
		font-size: 1.125rem;
		font-weight: 600;

		&:hover {
			#header & {
				color: $brand-primary;
				text-decoration: none;
			}
		}
	}

	a[data-depth="1"],
	a[data-depth="2"] {
		padding: 0 0.625rem 0.625rem 0;
	}

	.collapse {
		display: inherit;
	}

	.sub-menu {
		z-index: 18;
		width: calc(100% - 30px);
		min-width: calc(100% - 30px);
		margin-left: 0.9375rem;
		visibility: hidden;
		border: none;
		box-shadow: 2px 1px 11px 2px rgba(0, 0, 0, 0.1);
		opacity: 0;
		transition: opacity 0.5s ease, visibility 0.5s ease;

		&.collapse {
			visibility: hidden;
			opacity: 0;

			&.in {
				visibility: visible;
				opacity: 1;
			}
		}

		ul[data-depth="1"] > li {
			float: left;
			margin: 0 1.25rem;
		}

		a:hover {
			color: $brand-primary;
		}
	}

	.popover {
		max-width: inherit;
		border-radius: 0;
	}
}

.popover.bs-tether-element-attached-top {
	margin-top: 0;
}

#_desktop_top_menu {
	.top-menu {
		&[data-depth="0"] {
			li {
				&:hover {
					.sub-menu {
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}
	}
}

#mobile_top_menu_wrapper {
	padding: 15px;
	background: $white;

	#top-menu {
		margin-bottom: 0.625rem;
	}

	.top-menu {
		color: $gray-darker;

		.collapse-icons[aria-expanded="true"] {
			.add {
				display: none;
			}

			.remove {
				display: block;
			}
		}

		.collapse-icons .remove {
			display: none;
		}

		.navbar-toggler {
			display: inline-block;
			padding: 0;
		}

		a[data-depth="0"] {
			padding: 0.625rem;
			border-bottom: 1px solid $gray-lighter;
		}

		.collapse {
			display: none;

			&.in {
				display: block;
			}
		}

		.sub-menu {
			position: static;
			z-index: inherit;
			display: block;
			width: 100%;
			min-width: 100%;
			margin-left: 0;
			overflow: hidden;
			background: $white;
			box-shadow: none;

			&.collapse {
				display: none;

				&.in {
					display: block;
				}
			}

			ul[data-depth="0"] > li {
				border-bottom: 1px solid $gray;
			}

			ul[data-depth="1"] {
				margin: 0;

				> li {
					float: none;
					margin: 0;

					a {
						text-transform: none;
					}
				}
			}

			ul {
				padding: 0;
			}

			li > a {
				padding: 0.625rem;
				font-weight: 600;
				border-bottom: 1px solid $white;
			}

			ul[data-depth="2"] li a {
				padding-left: 1.25rem;
			}

			ul[data-depth="3"] li a {
				padding-left: 2.5rem;
			}

			ul[data-depth="4"] li a {
				padding-left: 3.75rem;
			}
		}
	}

	.js-top-menu-bottom {
		a {
			color: $gray;
		}

		.language-selector-wrapper {
			padding: 0.625rem;

			.language-selector {
				display: inline;
			}
		}

		.currency-selector {
			padding: 0.625rem;
		}

		#contact-link {
			padding: 0.625rem;
		}

		.user-info {
			padding: 0 0.625rem;

			a {
				display: block;
				width: 100%;
				padding: 0.625rem 0;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.top-menu {
		.sub-menu {
			visibility: visible;
			opacity: 1;
			transition: 0.5s ease-out;
		}
	}
}

#adtm_menu{
	margin-top: 0px !important;
}

#adtm_menu #menu {
	.li-niveau1 {
		border: 3px solid white;
		position: relative;
		&:hover {
			border: 3px solid $couleur-bordeaux-clair;
		}
		
		&>.adtm_sub{
			width: 280px;
			left: -4px !important;
			a{
				color: black;
				&:hover{
					color:white;
				}
			}
		}
	}
}
