#frame_wh_categories{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&>div{
		position: relative;
		width: calc(50% - 10px);
		margin-bottom: 10px;
		margin-top: 10px;
		
		@include mobile{
		  width: calc(100%);
		}
		
		&:nth-child(even){
			margin-left: 10px;
			@include mobile{
			  margin: 10px 0px;
			}
		}
		&:nth-child(odd){
			margin-right: 10px;
			@include mobile{
			  margin: 10px 0px;
			}
		}
		
		img{
			width: 100%;
		}
		
		.whcat-title{
			position: absolute;
			left:30px;
			bottom: 85px;
			z-index: 2;
			color: white;
			text-transform: capitalize;
			font-family: "brandon-grotesque", sans-serif;
			font-weight: $semi-bold;
			font-size: 1.6em;
			@include mobile{
				bottom: calc(50% - 10px);
			}
		}
		
		.whcat-btn-link{
			position: absolute;
			left:30px;
			bottom: 20px;
			z-index: 2;
			color: white;
			font-family: "brandon-grotesque", sans-serif;
			font-weight: $semi-bold;
			text-transform: uppercase;
			background-color: $couleur-bordeaux-clair;
			padding: 5px 10px;
			font-size: 0.8em;
			&:hover{
				color: $couleur-bordeaux-clair;
				background-color: white;
			}
			
			@include mobile{
				bottom: 10px;
			}
		}
	}
}