#footer{
	background-color: $gray-dark;
	width: 100% !important;
	.container{
		&>.row{
			&>div{
				float:none !important;
				&.blockreassurance{
					display: none !important
				}
			}
			#blockEmailSubscription_displayFooterBefore{
				background-color: $gray-dark;
				text-align: center;
				h2{
					font-family: "brandon-grotesque", sans-serif;
					font-weight: $semi-bold;
					text-transform: uppercase;
					color: white;
					margin-bottom: 20px;
				}
				
				input[type='email']{
					background-color: $couleur-bordeaux-clair;
					color: white;
					font-weight: $semi-bold;
					border: none;
					&::placeholder{
						color:white;
					}
				}
				
				p{
					color: white;
				}
				
				.btn-primary{
					color: $couleur-bordeaux-clair;
					background-color: white;
					border: 0px;
				}
				
				.col-xs-6{
					@include mobile{
						width: 80%;
					}
				}
			}
		}
		
	}
	.footer-container{
		padding: 0px;
		.bottom-bar{
			background-color: $couleur-bordeaux-clair;
			display: flex;
			flex-wrap: wrap;
			text-align: center;
			padding: 20px 0px;
			justify-content: center;
			align-items: center;
			a{
				font-family: "brandon-grotesque", sans-serif;
				font-weight: $semi-bold;
				text-transform: uppercase;
				padding: 0px 10px;
				color: white;
				display: inline-block;
			}
		}
	}
	
}

