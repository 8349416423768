.contact-rich {
  margin-bottom: 2rem;
  color: $gray;
  word-wrap: break-word;

  h4 {
    margin-bottom: 2rem;
    color: $gray-darker;
    text-transform: uppercase;
  }

  .block {
    height: auto;
    overflow: hidden;

    .icon {
      float: left;
      width: 3.5rem;

      i {
        font-size: 2rem;
      }
    }

    .data {
      width: auto;
      overflow: hidden;
      font-size: $font-size-sm;
      color: $gray-darker;

      &.email {
        padding-top: 0.375rem;
      }
    }

    a[href^="mailto:"] {
      margin-top: 1rem;
      clear: both;

      @include media-breakpoint-up(sm) {
        font-size: 0.815rem;
      }
    }
  }
}

.contact-form {
  width: 100%;
  padding: 1rem;
  color: $gray;
  background: $white;

  h3 {
    color: $gray-darker;
    text-transform: uppercase;
  }
}

#contact .group-span-filestyle label{
  margin-left: 10px;
}

#contact_layout{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  #leftcolumn{
    width: 50%;
    @include mobile{
      width: 100%;
    }
  }
  #rightcolumn{
    width: calc(50% - 30px);
    padding-left: 30px;
  }
  
  #adresses_contact{
    width: 100%;
    border-top:1px solid $gray-light-second;
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div{
      width: 33%;
    }
  }
}
