/* stylelint-disable */
@import "~manrope/complete/font.css";

@font-face {
	font-family: "Material Icons";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
	src: local("Material Icons"), local("MaterialIcons-Regular"),
		url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff2)
			format("woff2"),
		url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff)
			format("woff"),
		url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.ttf)
			format("truetype");
}
/* stylelint-enable */

.material-icons {
	display: inline-block;
	width: 1em;
	height: 1em;
	font-family: "Material Icons", Arial, sans-serif;
	font-size: 24px; /* Preferred icon size */
	font-style: normal;
	font-weight: 500;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	vertical-align: middle;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;

	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: "liga";
}

@import url("https://p.typekit.net/p.css?s=1&k=por4ddl&ht=tk&f=10294.10295.10296.10297&a=62121404&app=typekit&e=css");

@font-face {
	font-family: "brandon-grotesque";
	src: url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
			format("woff"),
		url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
			format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "brandon-grotesque";
	src: url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
			format("woff"),
		url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
			format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "brandon-grotesque";
	src: url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
			format("woff"),
		url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
			format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "brandon-grotesque";
	src: url("https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
			format("woff"),
		url("https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
			format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 700;
}

.tk-brandon-grotesque {
	font-family: "brandon-grotesque", sans-serif;
}
