@mixin search-box {
	form {
		position: relative;

		input[type="text"] {
			min-width: 255px;
			padding: 10px;
			color: white;
			border: none;
			//border: $input-btn-border-width solid $input-border-color;

			&:focus {
				color: white;
				outline: 3px $couleur-bordeaux-clair solid;

				+ button .search {
					color: white;
				}
			}
			
			@include mobile{
				outline: 3px $couleur-bordeaux-clair solid;
			}
		}

		button[type="submit"] {
			position: absolute;
			right: 0.125rem;
			bottom: 0.3125rem;
			color: $gray;
			background: none;
			border: none;

			.search:hover {
				color: white;
			}
		}
	}
}

@mixin box-shadow {
	box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
}

@mixin box-shadow-light {
	box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}


//Mixins Responsible
@mixin phone($orientation: null) {
	@if ($orientation == null) {
		@media (max-width: #{$phone-width - 1px}) {
			@content;
		}
	}@else{
		@media (orientation: $orientation) AND (max-width: #{$phone-width - 1px}) {
			@content;
		}
	}
}

@mixin tablet($orientation: null) {
	@if ($orientation == null) {
		@media (min-width: #{$phone-width}) and (max-width: #{$desktop-width - 1px}) {
			@content;
		}
	}@else{
		@media (orientation: $orientation) AND (min-width: #{$phone-width}) and (max-width: #{$desktop-width - 1px}) {
			@content;
		}
	}
}

@mixin mobile($orientation: null) {
	@if ($orientation == null) {
		@media (max-width: #{$desktop-width - 1px}) {
			@content;
		}
	}@else{
		@media (orientation: $orientation) AND (max-width: #{$desktop-width - 1px}) {
			@content;
		}
	}
}

@mixin desktop() {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}
