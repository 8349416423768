#frame_wh_subcategoriesmenu{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background-color: $gray-dark;
	margin-bottom: 30px;
	#inner_frame_wh_subcategoriesmenu{
		max-width: $width-compact;
		width: $width-compact;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		a{
			padding: 10px 20px;
			color: white;
			font-family: "brandon-grotesque";
			text-transform: uppercase;
			font-size: 1.1em;
			font-weight: bold;
			&:hover{
				background-color: $couleur-bordeaux-clair;
			}
			&.current{
				background-color: $couleur-bordeaux-clair;
			}
		}
	}
}