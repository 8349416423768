#custom-text {
	margin: 40px auto;
	.nos-caves-block{
		display: flex;
		flex-wrap: wrap;
		padding: 40px;
		background: $gray-dark;
		.nos-caves-gauche{
			width: 40%;
			@include mobile{
				width: 100%;
				img {
					width: 100%;
				}
			}
		}
		.nos-caves-droite{
			width: 60%;
			@include mobile{
				width: 100%;
				margin-top: 20px;
			}
			color: white;
			text-align: center;

			h2{
				text-transform: uppercase;
				position: relative;
				&::after{
					width: 40px;
					background: $couleur-bordeaux-clair;
					height: 1px;
					position: absolute;
					content: "";
					left: calc(50% - 20px);
					bottom: -10px;
				}
			}

			p{
				color: white;
				margin: 40px;
				@include mobile{
					margin: 20px;
				}
			}

			a{
				display: inline-block;
				background-color: white;
				color: $couleur-bordeaux-clair;
				padding: 10px 20px;
				font-weight: $semi-bold;
				text-transform: uppercase;
				&:hover{
					background-color: $couleur-bordeaux-clair;
					color: white;
				}
			}
		}
	}

	.presse{
		text-align: center;
		padding: 40px 0px;
		h2{
			text-transform: uppercase;
			position: relative;
			color: $gray-dark;
			&::after{
				width: 40px;
				background: $couleur-bordeaux-clair;
				height: 1px;
				position: absolute;
				content: "";
				left: calc(50% - 20px);
				bottom: -10px;
			}
		}

		.presse-texte{
			padding: 0px 20%;
			margin: 40px auto;
			@include mobile{
				width: 100%;
			}
		}

		ul{
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			list-style-type: none;
			align-items: center;
			li{
				width: 33%;
				text-align: center;
				img{
					max-height: 90px;
					max-width: 180px;
					@include mobile{
						max-width: 90%;
					}
				}
			}
		}
	}

	.magnets{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.inner-magnet{
			position: relative;
			width: calc(50% - 10px);
			
			@include mobile{
				width: 100%;
				margin-bottom: 10px;
			}

			&:nth-child(even){
				margin-left: 10px;
				@include mobile{
					margin-left: 0px;
				}
			}
			&:nth-child(odd){
				margin-right: 10px;
				@include mobile{
					margin-right: 0px;
				}
			}

			img{
				width: 100%;
			}

			&>div{
				position: absolute;
				right:30px;
				bottom: 85px;
				z-index: 2;
				color: white;
				text-transform: uppercase;
				font-family: "brandon-grotesque", sans-serif;
				font-weight: $semi-bold;
				font-size: 1.5em;
				@include mobile{
					right:20px;
					font-size: 1.3em;
				}
			}

			&>a{
				position: absolute;
				right:30px;
				bottom: 20px;
				z-index: 2;
				color: white;
				font-family: "brandon-grotesque", sans-serif;
				font-weight: $semi-bold;
				text-transform: uppercase;
				background-color: $gray-dark;
				padding: 5px 10px;
				font-size: 0.8em;
				&:hover{
					color: $couleur-bordeaux-clair;
					background-color: white;
				}
			}
		}
	}
}

.page-content.page-cms {
  padding: 1.25rem;
  text-align: justify;
  background: $white;

  .cms-box {
    img {
      max-width: 100%;
    }
  }

  ul {
    @extend p;
    list-style: disc inside none;
  }
}

@include media-breakpoint-down(md) {
  #block-cmsinfo {
    padding: $medium-space $large-space;
  }
}
