#module-eventsmanager-events{
	.container{
		.breadcrumb{
			display: none;
		}
	}
	
	#content{
		@include mobile{
			padding: 0px;
		}
	}
	
	#calendar_form{
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		flex-basis: 100%;
		flex-wrap: wrap;
		a{
			font-size: 0.8em !important;
		}
		
		.btn{
			width: 100%;
			margin: 10px 20px;
		}
	}
	
	#featured_event_frame{
		position: relative;
		margin-bottom: 20px;
		img{
			max-width: 100%;
		}
		a{
			background: $couleur-bordeaux-clair;
			color: white;
			text-transform: uppercase;
			font-weight: $semi-bold;
			font-family: "brandon-grotesque";
			padding: 5px 10px;
			margin-right: 20px;
			margin-bottom: 20px;
			position: absolute;
			bottom: 10px;
			right: 20px;
			&:hover{
				background-color: white;
				color: $couleur-bordeaux-clair;
			}
		}
	}
	
	.heading_calendar{
		text-transform: uppercase;
		font-weight: $semi-bold;
		font-family: "brandon-grotesque";
		margin-top: 20px;
	}
	.calendar-day-head{
		text-transform: uppercase;
		font-weight: $semi-bold;
		font-family: "brandon-grotesque";
	}
	
	a.control{
		color: black;
		text-transform: uppercase;
		font-weight: $regular;
		font-family: "brandon-grotesque";
		padding: 5px 10px;
		margin-right: 10px;
		line-height: 2em !important;
		height: 2.5em;
		&:hover{
			background-color: white;
			color: $couleur-bordeaux-clair;
		}
	}
	
	h3.event-detail-heading{
		text-align: center;
	}
	
	h1{
		display: inline-block;
		margin-top: 10px !important;
		color:$couleur-bordeaux-clair;
		font-weight: $semi-bold;
		font-style: italic;
	}
	
	#container_events{
		display: flex;
		flex-wrap: wrap;
		width: 70%;
		margin: auto;
		p{
			text-align: center;
			margin: 30px 0px 40px 0px;
			width: 100%;
		}
		.conteneur_calendar{
			width: 100%;
		}
		
		@include mobile{
			width: 100%;
		}
	}
	
	.calendar_outerdiv{
		display: block;
		&>a{
			@include mobile{
				font-size: 9px;
			}
		}
		
		&>h1{
			@include mobile{
				font-size: 19px;
			}
		}
		#calendardiv{
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: nowrap;
			@include mobile{
				flex-wrap: wrap;
			}
			#calendar_inner_div{
				width: 70%;
				
				table.calendar{
					width: 100% !important;
					font-family: "brandon-grotesque";
					background: none !important;
					td{
						width: 14.28% !important;
						background-image: none !important;
						background: none !important;
						background-color: #ebe9e4 !important;
						border-radius: 0px;
						&.calendar-day-head{
							font-size: 0.7em;
							color: black !important;
							border: 0px !important;
						}
						&.calendar-day{
							font-size: 20px !important;
							border: 0px !important;
							height: 80px !important;
							box-shadow: none !important;
							&:hover{
								background-color: $couleur-bordeaux-clair !important;
								color: white;
							}
						}
						&.eventpresent{
							background-color: #4d4d4d !important;
							background: #4d4d4d !important;
							color: white;
							a{
								display: none;
							}
							div{
								display: none;
							}
						}
						text-align: center;
						p{
							display: none;
						}
					}
				}
			}
			#calendar_right_div{
				text-align: center;
				h4{
					font-family: "brandon-grotesque";
					text-transform: uppercase;
					margin-top: 70px;
					text-align: center;
					font-weight: 500;
					font-style: italic;
					margin-bottom: 50px;
				}
				font-family: "brandon-grotesque";
				width: 30%;
				background-color: #4d4d4d !important;
				color : white;
				
				@include mobile{
					width: 100%;
				}
				
				select{
					margin: 5px 20px;
					width: calc(100% - 40px);
					background-color: white;
					border: 0px;
				}
				
				.btn{
					background-color: $couleur-bordeaux-clair;
					color: white;
				}
				
				p{
					color: white;
					text-align: center;
				}
			}
		}
		
	}
	
	.footer_calendar{
		background-color: #ebe9e4 !important;
		padding: 30px 0px;
		text-align: center;
		h3{
			font-family: "brandon-grotesque";
			text-transform: uppercase;
			margin-top: 10px !important;
			color: black;
			font-weight: $semi-bold;
			font-size: 1.6em;
			margin-bottom: 40px;
			@include mobile{
				font-size: 1.4em;
			}
		}
		
		.nav_events{
			background-color: black;
			ul{
				display: flex;
				flex-wrap: nowrap;
				width: 70%;
				margin: auto;
				justify-content: center;
				li{
					font-family: "brandon-grotesque";
					text-transform: uppercase;
					padding: 20px 10px;
					font-weight: $semi-bold;
					font-size: 1em;
					&:hover{
						background-color: $couleur-bordeaux-clair !important;
					}
					
					a{
						color: white;
						@include mobile{
							font-size: 13px;
						}
					}
				}
			}
		}
		
		.listing_events{
			width: 100%;
			display: flex;
			padding-top: 40px;
			#mat_events{
				display: flex;
				flex-wrap: wrap;
				width: 70%;
				margin: auto;
				padding: 30px auto;
				justify-content: space-between;
				align-items: flex-start;
				.mat_event_single_holder{
					width: calc(30% - 20px);
					@include mobile{
						width: calc(100% - 20px);
					}
					margin : 10px 10px; 
					background-color: transparent;
					padding : 0px !important;
					border: 0px !important;
					box-shadow: none !important;
					.mat_event_single_holder_inner{
						padding: 0px;
						text-align: center;
						.img_event{
							background-size: cover;
							background-position: center center;
							@include mobile{
								height: 200px !important;
							}
						}
						
						.mat_event_content{
							text-align: center;
							.tagnames{
								color: $couleur-bordeaux-clair;
								font-style: italic;
								font-weight: $semi-bold;
								margin-top: 20px;
								text-transform: uppercase;
							}
							
							h3{
								margin-top: 0px;
								margin-bottom: 0px;
								color: black !important;
								font-size: 1.3em;
								font-style: italic;
								a{
									color: black !important;
								}
							}
						}
						
						.mat_event_date{
							color: $couleur-bordeaux-clair;
							margin-top:10px;
							padding-right: 0px !important;
						}
					}
				}
			}
		}
		
	}
	
	#head_event{
		text-align: center;
		h1{
			color: white;
			line-height: 200px;
			font-size: 70px;
			font-weight: 700;
		}
	}
	
	#date_depart,#date_fin{
		font-family: "brandon-grotesque";
		text-transform: uppercase;
		color: $couleur-bordeaux-clair !important;
		font-weight: $semi-bold
	}
	
	.eventcontent{
		*{
			color: black;
		}
	}
	
	.bouton_resa{
		font-family: "brandon-grotesque";
		text-transform: uppercase;
		padding: 10px 35px;
		margin-top: 40px;
		font-weight: $semi-bold;
		font-size: 0.8em;
		color: white;
		text-decoration: none !important;
		background-color: $couleur-bordeaux-clair !important;
		&:hover{
			background-color: black !important;
		}
	}
	
	#prixmini{
		font-family: "brandon-grotesque";
		text-transform: uppercase;
		font-weight: $semi-bold;
		font-size: 1.5em;
		color: $couleur-bordeaux-clair
	}
	
	.heureevent{
		font-family: "brandon-grotesque";
		text-transform: lowercase;
		color: black;
		font-weight: $regular;
		font-size: 0.8em;
	}
	
	.fmm-add-to-cart-block{
		@include mobile{
			width:75% !important;
		}
		a{
			-webkit-appearance: unset !important;
		}
	}
}