#popup_whmajeur{
	position: fixed;
	top:0px;
	left: 0px;
	width:100%;
	height: 100%;
	background-color: rgba(0,0,0,0.7);
	z-index: 11;
	display: flex;
	flex-wrap: wrap;
	font-family: "brandon-grotesque";
	
	&>div{
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		width: 40%;
		background-color: white;
		margin: auto;
		padding: 20px;
		justify-content: center;
		font-weight: $semi-bold;
		
		@include mobile{
			width: 90%;
		}
		
		span:first-child{
			text-align: center;
			width: 100%;
			text-transform: uppercase;
			font-weight: $semi-bold;
			margin-bottom: 20px;
			display: block;
			color: black;
		}
		span#popup_whmajeur_oui{
			background-color: $couleur-bordeaux-clair;
			color:white;
			text-transform: uppercase;
			font-weight: $semi-bold;
			padding: 5px 8px;
			margin-right: 10px;
			cursor: pointer;
			&:hover{
				opacity: 0.8;
			}
		}
		
		span#popup_whmajeur_non{
			background-color: black;
			color: white;
			text-transform: uppercase;
			font-weight: $semi-bold;
			padding: 5px 8px;
			cursor: pointer;
			&:hover{
				opacity: 0.8;
			}
			
			@include mobile{
				margin-top: 20px;
			}
		}
	}
}