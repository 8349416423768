#wrapper {
  padding-top: 1.563rem;
  background: white;

  .banner {
    display: block;
    margin-bottom: 1.5rem;
  }

  .breadcrumb {
    padding: 0;
    background: transparent;

    &[data-depth="1"] {
      display: none;
    }

    ol {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      display: inline;

      &::after {
        margin: 0.3125rem;
        color: $gray;
        content: ">";
      }

      &:last-child {
        margin: 0;
        color: $couleur-bordeaux-clair;
        content: ">";

        &::after {
          content: "";
        }
      }

      a {
        color: $gray-darker;
      }
    }
  }
}

#cms{
  .noscaves{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    
    a{
      background: $couleur-bordeaux-clair;
      color: white;
      text-transform: uppercase;
      font-weight: $semi-bold;
      font-family: "brandon-grotesque";
      padding: 5px 10px;
      margin-right: 20px;
      margin-bottom: 20px;
      font-size: 0.9em;
      display: block;
      &:hover{
        background-color: white;
        color: $couleur-bordeaux-clair;
      }
    }
    
    h1{
      text-transform: uppercase;
      color: $couleur-bordeaux-clair;
      text-align: left;
      font-size: 1.175em;
    }
    
    #presentation{
      width: calc(40% - 10px);
      box-sizing: border-box;
      padding: 10px;
      margin-bottom: 20px;
      margin-right: 10px;
    }
    #maps{
      width: 60%;
      box-sizing: border-box;
      padding: 10px;
      margin-bottom: 20px;
    }
    #equipe{
      width: calc(33% - 10px);
      margin-right: 10px;
      box-sizing: border-box;
      padding: 10px;
    }
    #moments{
      width: calc(33% - 10px);
      margin-right: 10px;
      box-sizing: border-box;
      padding: 10px;
    }
    #cadeaux{
      width: 33%;
      box-sizing: border-box;
      padding: 10px;
    }
    
    &>div{
      border: 1px solid #ddd;
      @include mobile{
        width: 100% !important;
        margin: 0px;
        margin-bottom: 10px;
      }
    }
  }
}
