#header {
	position: relative;
	z-index: 999;
	color: $gray;
	background: $white;
	//box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);

	.logo {
		max-width: 100%;
		height: auto;
	}

	#_desktop_logo {
		h1 {
			margin-bottom: 0;
		}
	}

	a {
		color: white;
		text-transform: uppercase;
		font-weight: $semi-bold;

		&:hover {
			color: $brand-primary;
			text-decoration: none;
		}
	}

	.menu {
		display: inline-block;

		> ul {
			> li {
				display: inline-block;
			}
		}
	}

	.header-nav {
		max-height: 50px;
		border-bottom: $gray-light 2px solid;
		background-color: $couleur-bordeaux-clair;
		color: white;
		text-transform: uppercase;
		font-weight: $semi-bold;
		font-size: 0.8em;
		padding: 10px 0px 10px 0px;
		line-height: 0.8em;

		#accroches {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
		}


		.right-nav {
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-end;
		}

		.currency-selector {
			margin-top: 0.9375rem;
			margin-left: 0.9375rem;
			white-space: nowrap;
		}

		.user-info {
			margin-top: 0.9375rem;
			margin-left: 2.5rem;
			text-align: right;
			white-space: nowrap;

			.account {
				margin-left: $small-space;
			}
		}

		.language-selector {
			margin-top: 0.9375rem;
			white-space: nowrap;
		}

		.cart-preview {
			&.active {
				background: $brand-primary;

				a,
				i {
					color: $white;
				}
			}

			.shopping-cart {
				color: $gray;
				vertical-align: middle;
			}

			.body {
				display: none;
			}
		}

		.blockcart {
			height: 3rem;
			padding: 0.75rem;
			margin-left: 0.9375rem;
			text-align: center;
			white-space: nowrap;
			background: $gray-light;
			@include mobile{
				background: white;
			}

			a {
				color: $gray;

				&:hover {
					color: $brand-primary;
				}
			}

			&.active {
				a:hover {
					color: $white;
				}
			}

			.header {
				margin-top: 0.125rem;
			}
		}

		#_desktop_contact_link {
			display: inline-block;

			#contact-link {
				margin-top: 0.9375rem;
			}
		}

		.search-widget {
			margin-top: 0.2rem;
		}

		.material-icons {
			line-height: inherit;
			&.expand-more {
				margin-left: -0.375rem;
			}
		}
		
		
	}

	.header-top {
		padding: 0rem 0;
		
		@include tablet{
			margin-top: 20px;
		}
		
		&.stickyNav{
			z-index: 25 !important;
		}

		> .container {
			position: relative;

			> .row:first-of-type {
				display: flex;
				align-items: center;
				@include mobile{
					display: block;
				}
			}
			
			#menumobile{
				.clearfix{
					@include mobile{
						display: none;
					}
				}
				
				@include mobile{
					background-color: white;
					display: flex;
					flex-wrap: nowrap;
					min-width: 100%;
					justify-content: space-between;
					flex-basis: 100%;
					margin-bottom: 10px;
				}
				
				#menu-icon {
					margin: 0 1rem;
					vertical-align: middle;
					cursor: pointer;
				
					.material-icons {
						line-height: 50px;
						@include mobile{
							color: black;
							font-size: 35px;
						}
					}
				}
				
				.blockcart{
					.header{
						@include mobile{
							position: relative;	
							margin-right: 20px;
						}
						.cart-products-count{
							@include mobile{
								position: absolute;
								top: 0px;
								z-index: 10;
								background-color: $couleur-bordeaux-clair;
								right: -20px;
								color: white;
								border-radius: 15px;
								padding: 5px;
							}
						}
					}
					
					
				}
			}

			#conteneur-top {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-end;
				align-items: center;
				
				@include mobile{
					flex-basis: 100%;
				}
				
				#_desktop_cart{
					padding-bottom: 5px;
				}

				#block_myaccount_infos {
					.myaccount-title {
						margin: auto 20px;
						padding-bottom: 5px;
					}
					position: relative;
					#footer_account_list {
						display: none;
						border: 3px solid $couleur-bordeaux-clair;
						a {
							color: $couleur-bordeaux-clair;
							margin: 5px 10px;
							font-size: 0.9em;
							&:hover {
								color: black;
							}
						}
					}

					&:hover {
						#footer_account_list {
							display: block;
							position: absolute;
							left: 0px;
							top: 35px;
							width: auto;
							min-width: 270px;
							z-index: 25;
							background: white;
						}
					}
					
					.title{
						@include mobile{
							display: none;
						}
					}
				}

				.blockcart {
					margin: auto 30px;
					.header {
						position: relative;
						.cart-products-count {
							color: white;
							background-color: $couleur-bordeaux-clair;
							position: absolute;
							right: -15px;
							top: -15px;
							width: 30px;
							height: 30px;
							line-height: 30px;
							display: block;
							text-align: center;
							border-radius: 15px;
						}
					}
				}
				

				#search_widget {
					margin-left: 30px;
					input::placeholder{
						color: white;
					}
					
					@include tablet{
						width: 50%;
						margin-left: 0px;
					}
					
					@include phone{
						width: 100%;
						margin-left: 0px;
					}
				}
			}
		}

		.menu {
			padding-left: 15px;
		}

		&-right {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.position-static {
			position: static;
		}

		a[data-depth="0"] {
			color: $gray;
			text-transform: uppercase;
		}

		.search-widget {
			//float: right;
		}
	}

	.top-menu-link {
		margin-left: $medium-space;
	}

	@include media-breakpoint-down(sm) {
		.top-logo {
			display: flex;
			align-items: center;
			min-height: 50px;

			img {
				width: auto;
				max-height: 5rem;
			}

			> h1 {
				margin: 0;
			}
		}
	}
	
	.js-top-menu.mobile {
		#menu{
			li{
				a{
					border-width: 0px;
					border-bottom-width: 1px;
					min-height: 30px;
					line-height: 30px;
					
					span{
						min-height: 30px;
						line-height: 30px;
						&.advtm_menu_span.advtm_menu_span_5{
							background-color: white !important;
							background: none !important;
							color: black;
							&:hover{
								background-color: white;
							}
						}
					}
					
					&.advtm_menu_actif{
						span{
							background-color: white !important;
							background: none !important;
						}					
					}
				}
				div{
					border-width: 0px;
				}
			}
		}
	}
}

#socials{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	a{
		img{
			height: 17px;
			margin: auto 6px;
		}
		&:nth-child(3),&:nth-child(4){
			img{
				margin: auto 2px;
			}
		}
	}
}


.li-niveau1 a span{
	@include tablet{
		padding: 0px 10px !important;
	}
}

.carousel-inner{
	margin-bottom: 50px !important;
}