/*** SOME GENERIC STYLES ***/
@mixin customer-area-base-box {
  padding: 1rem;
  background: $white;
}

@mixin customer-area-box {
  @include customer-area-base-box();
  font-size: $font-size-sm;
  color: $gray;
}

.table-labeled {
  th {
    vertical-align: middle;
  }

  td {
    vertical-align: middle;
  }

  .label {
    padding: 0.25rem 0.375rem;
    margin: 0.125rem;
    font-size: inherit;
    font-weight: 400;
    color: $white;
    white-space: nowrap;
    border-radius: 3px;
  }
}

.page-order {
  .table {
    margin-bottom: 0;
  }

  table {
    th {
      padding: 0.5rem;
    }

    td {
      padding: 0.5rem;
    }

    thead {
      th {
        text-align: center;
      }
    }
  }
}

#authentication {
  .tooltip.tooltip-bottom {
    padding: 0;
    margin: 0;
  }

  .custom-checkbox {
    display: flex;

    span {
      flex: 0 0 0.9375rem;
    }

    label {
      padding-left: 0.625rem;
    }
  }
}

#identity,
#authentication {
  .radio-inline {
    padding: 0;

    .custom-radio {
      margin-right: 0;
    }
  }
}

/*** Most of the customer accpunt pages ***/
.page-customer-account {
  #content {
    @include customer-area-box();

    .order-actions {
      a {
        padding: 0 0.125rem;
      }
    }

    .forgot-password {
      padding-bottom: 0.9375rem;
      margin-top: 1rem;
      font-size: $font-size-sm;
      text-align: center;
    }

    .no-account {
      font-size: $base-font-size;
      text-align: center;
    }
  }
}

/*** Login page ***/
.page-authentication {
  #content {
    @include customer-area-base-box();
    max-width: 640px;
    margin: 0 auto;
  }
}

/*** Addresses page ***/
.page-addresses {
  .address {
    margin-bottom: 1.875rem;
    font-size: $font-size-sm;
    color: $gray-darker;
    background: $white;

    .address-body {
      padding: 1rem;

      h4 {
        font-size: $base-font-size;
        font-weight: 700;
      }

      address {
        min-height: 9rem;
      }
    }

    .address-footer {
      padding: 0.5rem 1rem;
      border-top: 1px solid $gray;

      a {
        margin-right: 0.5rem;
        color: $gray;

        &:hover {
          color: $gray-darker;
        }

        i {
          font-size: $base-font-size;
        }

        span {
          font-size: $font-size-lg;
          vertical-align: middle;
        }
      }
    }
  }

  .addresses-footer {
    margin: 0 0.9375rem;

    a {
      color: $gray-darker;

      &:hover {
        color: $gray-darker;
      }

      i {
        font-size: $base-font-size;
      }

      span {
        margin-top: $small-space;
        font-size: $base-font-size;
        vertical-align: middle;
      }
    }
  }
}

/*** Order details page ***/
.page-order-detail {
  #content {
    font-size: 0.875rem;
  }

  .box {
    @include customer-area-base-box();
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 1rem;
    font-size: $base-font-size;
    font-weight: 700;
    color: $gray-darker;
    text-transform: uppercase;
  }

  #order-infos {
    ul {
      margin: 0;
    }
  }

  #order-history {
    .history-lines {
      .history-line {
        padding: 0.5rem 0;
        border-bottom: 1px solid $gray-lighter;

        &:last-child {
          border-bottom: 0;
        }

        .label {
          display: inline-block;
          padding: 0.25rem 0.375rem;
          margin: 0.25rem 0;
          color: $white;
          border-radius: 3px;
        }
      }
    }
  }

  .addresses {
    margin: 0 -0.9375rem;

    h4 {
      font-size: $base-font-size;
      font-weight: 700;
    }
  }

  #order-products {
    &.return {
      margin-bottom: 1rem;

      th {
        &.head-checkbox {
          width: 30px;
        }
      }

      td {
        padding: 1.375rem 0.75rem;

        &.qty {
          min-width: 125px;

          .current {
            float: left;
            width: 30%;
            padding-right: 0.5rem;
            text-align: right;
          }

          .select {
            float: left;
            width: 70%;
            padding-left: 0.25rem;
            margin: -0.625rem 0;

            select {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .order-items {
    // stylelint-disable-next-line
    padding: 0 !important;

    .order-item {
      padding: 1rem 1rem 0;
      border-bottom: 1px solid $gray-lighter;

      .checkbox {
        float: left;
        width: 30px;
        padding: 0 0.9375rem;
      }

      .content {
        float: left;
        width: calc(100% - 30px);
        padding: 0 0.9375rem;
      }

      .desc {
        margin-bottom: 1rem;

        .name {
          font-weight: 600;
        }
      }

      .qty {
        margin-bottom: 1rem;

        .q {
          margin-bottom: 0.25rem;
        }

        .s {
          margin-bottom: 0.25rem;
        }
      }
    }
  }

  .messages {
    .message {
      margin-top: 0.5rem;
      border-bottom: 1px solid $gray-lighter;

      &:last-child {
        border-bottom: 0;
      }

      > div {
        margin-bottom: 0.5rem;
      }
    }
  }

  .customization {
    margin-top: 0.75rem;
  }
}

/*** Order return page ***/
#order-return-infos {
  .thead-default th {
    color: $gray-darker;
  }

  .customization {
    margin-top: 0.75rem;
  }
}

/*** My account landing page ***/
.page-my-account {
  #content {
    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      a {
        display: inline-block;
        padding: 0 0.9375rem;
        margin-bottom: 1.875rem;
        font-size: $base-font-size;
        font-weight: 700;
        color: $gray;
        text-align: center;
        text-transform: uppercase;

        span.link-item {
          display: block;
          height: 100%;
          @include customer-area-base-box();
        }

        i {
          display: block;
          width: 100%;
          padding-bottom: 3.4rem;
          font-size: 2.6rem;
          color: $gray-darker;
        }

        &:hover {
          color: $gray-darker;

          i {
            color: $brand-primary;
          }
        }
      }
    }
  }
}

/*** History page ***/
#history {
  .orders {
    margin: 0 -1rem;

    .order {
      padding: 0.75rem 1rem;
      border-bottom: 1px solid $gray-lighter;

      a {
        h3 {
          color: $gray;
        }
      }

      .label {
        display: inline-block;
        padding: 0.25rem 0.375rem;
        margin: 0.25rem 0;
        color: $white;
        border-radius: 3px;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

/*** FOOTER ***/
.page-footer {
  .account-link {
    margin-right: 1rem;

    i {
      font-size: $base-font-size;
    }

    span {
      font-size: $font-size-sm;
      vertical-align: middle;
    }
  }
}

.login-form {
  margin-top: 15px;
}
