#block-reassurance {
  margin-top: 40px;
  background: url("../../../../modules/blockreassurance/views/img/reassurance/fond-reassurance.png");
  ul{
    display: flex;
    flex-wrap: wrap;
    font-family: "brandon-grotesque", sans-serif;
    margin-bottom: 50px !important;
    li {
      padding: 120px 0px;
      text-decoration: center;
      width: 33%;
      color: white;
      text-align: center;
      @include mobile{
        padding: 60px 0px;
      }
      
      img {
        width: 50px;
      }
      
      .block-title{
        text-transform: uppercase;
        width: 70%;
        font-weight: $semi-bold;
        font-size: 1.2em;
        margin: 20px auto;
        max-width: 70%;
        display: block;
        min-height: 40px;
        @include mobile{
          font-size: 1em;
        }
      }
      
      
      .block-description{
        width: 70%;
        font-size: 1em;
        max-width: 70%;
        margin: auto;
        text-align: center;
        line-height: 1.2em;
        @include mobile{
          display: none;
        }
      }
      
    }
  }
  
}


#product{
  #block-reassurance {
    li {
      padding: 40px 0px;
      
      .block-title{
        margin: 20px auto 0px auto;
      }
      
      .block-description{
          display: none;
      }
    }
  }
}