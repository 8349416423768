$thumbnail-size: 250px;
$product-description-height: 70px;

#left_featured{
  .prods{
    &>div{
      height: 100px;
      a{
        display: flex;
        img{
          float: left;
          height: 80px;
          margin-right: 20px;
        }
        text-transform: uppercase;
        color: black;
        align-items: center;
      }
    }
  }
  @include mobile{
    display: none;
  }
}

#products,
.featured-products,
.product-accessories,
.product-miniature,#wh_product_cats {

  &.featured-products{
    background-color: $gray-dark;
    padding: 20px;
    text-align: center;
    margin-bottom: 50px;
  }
  
  &#wh_product_cats{
    
    display: flex;
    flex-wrap: wrap;
    .sub-cat{
      text-align: center;
      width: calc(50% - 10px);
      background-color: $couleur-beige-cat;
      @include mobile{
        width: calc(100%);
        margin-bottom: 20px;
      }
      
      h2{
        font-family: "brandon-grotesque", sans-serif;
        font-weight: $semi-bold;
        text-transform: uppercase;
        color: white;
        height: 100px;
        margin-bottom: 20px;
        background-repeat: no-repeat;
        display:flex;
        span{
          margin: auto;
        }
      }
      
      .product {
        max-width: calc(100% / 3);
        width: calc(100% / 3);
        @include mobile{
          width: 50%;
          max-width: calc(100% / 2);
          width: calc(100% / 2);
          &:nth-last-child{
            display: none;
          }
        }
      }
      
      &:nth-child(odd){
        margin-right: 10px;
        @include mobile{
          margin-right: 0px;
        }
      }
      &:nth-child(even){
        margin-left: 10px;
        @include mobile{
          margin-left: 0px;
        }
        
      }
      
      .all-product-link{
        background-color: $couleur-bordeaux-clair;
        color: white;
        margin: auto;
      }
    }
  }

  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    &.accessoireproduct{
      &>div{
        width: calc(100% / 6);
        @include mobile{
          width: calc(100% / 2);
        }
        
        .overlay_product{
          display: none;
        }
      }
    }
  }

  .product-thumbnail {
    display: block;
  }

  .product-title a {
    font-size: 0.8em;
    color: $gray-dark;
    text-align: center;
    text-decoration: none;
    font-family: "brandon-grotesque", sans-serif;
    font-weight: $semi-bold;
    text-transform: uppercase;
    height: 40px;
    display: block;
  }

  .thumbnail-container {
    position: relative;
    //width: $thumbnail-size;
    height: auto;
    margin-bottom: 1.563rem;
    overflow: hidden;
    background: $white;

    &:hover,
    &:focus {
      .highlighted-informations {
        top: calc(250px - 4.4rem);
        bottom: 5.5rem;

        &::after {
          opacity: 1;
        }

        &.no-variants {
          top: calc(250px - 2.5rem);
          bottom: 2.2rem;
        }
      }

      .product-description::after {
        position: absolute;
        top: 0;
        left: 10%;
        width: 80%;
        content: "";
        //border-top: $gray 1px solid;
        opacity: 0.25;
      }
    }

    .product-thumbnail {
      position: relative;
      height: calc(100% - #{$product-description-height});

      img {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }
  }

  .products-section-title {
    margin: 0px;
    font-weight: 500;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-weight: $extra-bold;
    margin-bottom: 20px;
  }

  .product-title {
    margin-top: 0.7rem;
    text-align: center;
    text-transform: capitalize;
  }

  .product-price-and-shipping {
    font-weight: 700;
    color: $couleur-bordeaux-clair;
    text-align: center;
    font-size: 0.8em;

    .discount-product {
      display: none;
    }
  }

  .variant-links {
    position: relative;
    top: -0.25em;
    width: 100%;
    min-height: 2.5rem;
    padding-top: 0.1875rem;
    text-align: center;
    background: $white;
  }

  .highlighted-informations {
    position: absolute;
    top: $thumbnail-size;
    bottom: 1.25rem;
    z-index: 2;
    width: $thumbnail-size;
    height: auto;
    padding-top: 0.625rem;
    text-align: center;
    background: $white;
    transition: top 0.3s, bottom 0.3s;

    .quick-view {
      font-size: $base-font-size;
      color: $gray;

      &:hover {
        color: $brand-primary;
      }
    }
  }

  .product-description {
    position: relative;
    bottom: 0;
    z-index: 3;
    width: 100%;
    height: auto;
    //padding-bottom: 0.7rem;
    overflow: hidden;
    background: $white;
  }

  .product {
    padding: 0;
    max-width: calc(100% / 3);
    width: calc(100% / 3);

    @at-root .page-index &, .page-search & {
      width: 16.6%;
      //min-width: 250px;
      @include mobile{
        width: calc(100% / 2);
      }
    }
  }

  .product-miniature {
    margin: 0 0.8125rem;

    .product-flags {
      li.product-flag {
        font-weight: 600;

        &.online-only {
          top: 13.1rem;
        }
      }
    }
  }

  .comments_note {
    color: $gray;
    text-align: center;
  }

  .regular-price {
    display: inline-block;
    font-size: 0.8em;
    color: $couleur-rouge-promo;
    //text-decoration: line-through;
  }

  .strikethrough {
	  position: relative;
	}
	.strikethrough:before {
	  position: absolute;
	  content: "";
	  left: 0;
	  top: 50%;
	  right: 0;
	  border-top: 1px solid;
	  border-color: inherit;

	  -webkit-transform:rotate(-5deg);
	  -moz-transform:rotate(-5deg);
	  -ms-transform:rotate(-5deg);
	  -o-transform:rotate(-5deg);
	  transform:rotate(-5deg);
	}

  .count {
    position: relative;
    bottom: 0.5rem;
    font-weight: 700;
    color: $gray;
  }

  .all-product-link {
    clear: both;
    display: inline-block;
    font-weight: 500;
    color: $couleur-bordeaux-clair;
    border: 2px solid $couleur-bordeaux-clair;
    padding: 15px 20px;
    float: none !important;
    text-transform: uppercase;
    font-family: "brandon-grotesque" !important;
    font-weight: $bold;
    margin: 20px auto !important;
    font-size: 0.9em;
    &:hover{
	    background-color: rgba(255,255,255,0.9);
    }

    @at-root .lang-rtl & {
      .material-icons {
        transform: rotate(-180deg);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .featured-products {
    .products {
      justify-content: center;
    }
  }
}
