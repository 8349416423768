#footer {
  padding-top: 1.7rem;
}

#evin{
  color: white;
  font-size: 0.7em;
  margin-top: 10px;
  width: 100%;
}

.block-contact {
  font-size: 0.875rem;
  color: $gray;

  .block-contact-title {
    color: $gray-darker;
  }

  .navbar-toggler .material-icons {
    color: $gray-darker;
  }

  @include media-breakpoint-down(sm) {
    #contact-infos {
      padding: 0.625rem;
      padding-top: 0;
    }
  }
}

.linklist {
  .blockcms-title a {
    color: $gray-darker;
  }
}

.account-list a {
  color: $gray;

  &:hover {
    color: $brand-primary;
  }
}

.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-size: $base-font-size;
  font-weight: 700;
}

.block-social {
  text-align: right;

  ul {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-flow: wrap;
    }

    li {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      margin: 0.125rem;
      cursor: pointer;
      background-color: $gray-light;
      background-repeat: no-repeat;

      @include media-breakpoint-down(sm) {
        width: 2rem;
        height: 2rem;
        margin-right: 1.3rem;
        margin-bottom: 1.3rem;
        background-size: contain;
      }

      &:hover {
        background-color: $brand-primary;
      }

      a {
        display: block;
        height: 100%;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;

        &:hover {
          color: transparent;
        }
      }
    }
  }
}

.facebook {
  background-image: url("../img/facebook.svg");

  &::before {
    content: "";
    background-image: url("../img/facebook-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/facebook-gray.svg");

    &:hover {
      background-image: url("../img/facebook-blue.svg");
    }
  }
}

.twitter {
  background-image: url("../img/twitter.svg");

  &::before {
    content: "";
    background-image: url("../img/twitter-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/twitter-gray.svg");

    &:hover {
      background-image: url("../img/twitter-blue.svg");
    }
  }
}

.linkedin {
  background-image: url("../img/linkedin.svg");
  background-position: center center;
}

.rss {
  background-image: url("../img/rss.svg");
}

.youtube {
  background-image: url("../img/youtube.svg");
}

.googleplus {
  background-image: url("../img/gplus.svg");

  &::before {
    content: "";
    background-image: url("../img/gplus-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/gplus-gray.svg");

    &:hover {
      background-image: url("../img/gplus-blue.svg");
    }
  }
}

#block_myaccount_infos {
  .myaccount-title a {
    color: $gray-darker;
  }
}

.pinterest {
  background-image: url("../img/pinterest.svg");

  &::before {
    content: "";
    background-image: url("../img/pinterest-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/pinterest-gray.svg");

    &:hover {
      background-image: url("../img/pinterest-blue.svg");
    }
  }
}

.vimeo {
  background-image: url("../img/vimeo.svg");
}

.instagram {
  background-image: url("../img/instagram.svg");
}



.footer-container {
  padding-top: $extra-large-space;
  overflow: hidden;
  
  #socials{
      a{
        padding: 0px !important;
      }
    }

  .h3,
  .h4 {
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  li {
    margin-bottom: 0.3125rem;
  }

  li a {
    font-size: $font-size-sm;
    color: $gray;
    cursor: pointer;

    &:hover {
      color: $brand-primary;
    }
  }
}

.links {
  .collapse {
    display: inherit;
  }
}

@include media-breakpoint-down(sm) {
  .block_newsletter {
    padding-bottom: 0.625rem;
    border-bottom: 1px solid $gray-lighter;
  }

  .footer-container {
    margin-top: 0;
    box-shadow: none;

    .wrapper {
      /* stylelint-disable */
      padding-right: 0 !important;
      padding-left: 0 !important;
      /* stylelint-enable */
    }

    .links {
      .h3 {
        font-size: 1rem;
        line-height: 1.5;
      }

      ul {
        margin-bottom: 0;
        background-color: $gray-lighter;

        > li {
          padding: 0.625rem;
          font-weight: 600;
          border-bottom: 1px solid $white;

          a {
            color: $gray-darker;
          }
        }
      }
    }
  }

  .links {
    .collapse {
      display: none;

      &.in {
        display: block;
      }
    }

    .title {
      padding: 0.625rem;
      cursor: pointer;
      border-bottom: 1px solid $gray-lighter;

      .collapse-icons .remove {
        display: none;
      }
    }

    .title[aria-expanded="true"] .collapse-icons {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }
  }
}

#scrolltotop{
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: $couleur-bordeaux-clair;
  z-index: 4;
  width: 30px;
  height: 35px;
  text-align: center;
  line-height: 2em;
  cursor: pointer;
  span{
    display: block;
    color: white;
    font-size: 2em;
    font-weight: bold;
    font-family: "brandon-grotesque";
    transform: rotate(-90deg) translateY(-3px);
    
  }
}

.average_mark{
  display:flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: end;
  margin-bottom: 30px;
  min-height: 24px;
  span{
    color: #ff9600;
    font-weight: bold;
    font-family: "brandon-grotesque";
    font-size: 1.3em;
  }
}

#index .average_mark, #product .accessoireproduct .average_mark{
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    font-size:0.9em;
  }
  .comments-note{
    max-width: 60%;
    .star-content div{
      width: 15px;
      height: 15px;
      background: url(../../../../modules/productcomments/views/img/small_stars.png) no-repeat 0 0 transparent;
      
      &.star-on{
       background-position: -19px 0; 
      }
    }
  }
  
  
}

#category .average_mark{
  justify-content: center;
  align-items: end;
  span{
    font-size: 1.1em;
  }
}

.grade-num{
  color: #ff9600;
  font-weight: bold;
  font-family: "brandon-grotesque";
  font-size: 1.4em;
}

footer #gdpr_consent{
  text-align: center;
  span{
    color: white;
    i{
      color: white;
    }
  }
}

@include media-breakpoint-down(md) {
  .block-social {
    text-align: center;
  }

  .block-contact {
    padding-left: 1.5rem;
  }
}

