.search-widget {
	display: inline-block;
	@include search-box();
}

.header-top {
	.search-widget {
		form {
			input[type="text"] {
				width: 100%;
				min-width: inherit;
				color: black;
				background: $couleur-bordeaux-leger;

				&:focus {
					background: $white;
				}
			}

			button[type="submit"] {
				top: 0px;
				right: 0px;
				width: 45px;
				background: $couleur-bordeaux-clair;
				text-align: center;
				color: white;
				height: 100%;
			}
		}
	}
}

#checkout {
	#search_widget {
		display: none; /* Not ideal solution by allows to r;use same hooks/templates */
	}
}

#pagenotfound {
	.page-content {
		#search_widget {
			width: 100%;
		}
	}
}

.page-not-found {
	.search-widget {
		form {
			display: inline-block;
		}
	}
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
	.header-top {
		.search-widget {
			float: none;

			form {
				margin: 0 auto;

				input[type="text"] {
					min-width: inherit;
					background: $white;
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.search-widget {
		min-width: 15.63rem;
	}
}
